<template>
  <v-container fluid class="pa-0">
    <div class="img-cont">
      <img src="../../../../assets/images/logo_gestion.jpg" alt="" class="img-inicio" />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Inicio',

  data: () => ({})
};
</script>
<style lang="scss">
.imagen {
  width: 100%;
  height: calc(100vh - 61px - 64px - 24px);
  background: url('../../../../assets/images/logo_gestion.jpg') no-repeat;
  background-size: cover;
}

.img-cont {
  overflow: hidden;
}

.img-inicio {
  width: 100%;
  height: calc(100vh - 61px - 64px - 24px);
}
</style>
